import React, { useState, useEffect, useMemo } from 'react';
import { getDoc, doc, setDoc, updateDoc, getFirestore } from 'firebase/firestore/lite';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { useSearchParams, useNavigate } from "react-router-dom";
import { parseJwt } from '../../lib/helper';

import './styles.css';

const accEmail = 'updateBlockUser@gmail.com'
const pass = '!@#default'



function DeleteReaderAcc() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState({});
  const [textError, setTextError] = useState('');
  const [textInput, setTextInput] = useState('');
  const [token, setToken] = useState({});
  const navigate = useNavigate();


  const firebaseConfig = {
    apiKey: "AIzaSyBrPSTHrDEkTUL9i-WUlWgoCdCCaGeH2lM",
    authDomain: "voice-reader-35489.firebaseapp.com",
    projectId: "voice-reader-35489",
    storageBucket: "voice-reader-35489.appspot.com",
    messagingSenderId: "156850034699",
    appId: "1:156850034699:web:b977196b14565b3a058f0e",
    measurementId: "G-6RWZW56FXH"
  };

  const app = useMemo(() => initializeApp(firebaseConfig), ["fdsf"]);

  const auth = useMemo(() => {
    return getAuth(app)
  }, [app])

  const db = useMemo(() => {
    return getFirestore(app)
  }, [app])

  async function blockUser(uid) {
    if (!uid) {
      setTextError("Something error")
      return;
    }
    const docRef = doc(db, 'users', uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists() && !docSnap.data().blocked) {
      updateDoc(docRef, { blocked: true })
        .then(docRef => {
          goSuccess()
        })
        .catch(error => {
          goSuccess()
          console.log(error);
        })
    } else {
      goSuccess()
    }
  }

  async function _getAuth() {
    if (user && user.exp) {
      const checkExp = (user.exp * 1000) >= Date.now()
      if (checkExp) {
        signInWithEmailAndPassword(auth, accEmail, pass)
          .then((userCredential) => {
            blockUser(user.user_id)
          })
          .catch((error) => {
            setTextError("Something error 11")
          });
      } else {
        setTextError("Something error 22")
      }
      console.log('useruseruser', user, checkExp)
    } else {
      setTextError("Something error 33")
    }
  }
  useEffect(() => {
    const accessToken = searchParams.get("token")
    if (accessToken) {
      setToken(accessToken)
      const user = parseJwt(accessToken)
      setUser(user)
    }
  }, [searchParams])

  const onDelete = () => {


    if (!user || !user.user_id) {
      setTextError("Account Id not match")
      return;
    }
    if (user.user_id !== textInput) {
      setTextError("Account Id not match")
      return;
    }

    _getAuth()
    console.log('detefsdfsfsfd', textInput, user.user_id)
  }

  const onCancel = () => {
    navigate('/');
  }

  const goSuccess = () => {
    navigate('/delete-success');
  }

  const onChangeText = (e) => {
    console.log('fdsfdsf', e.target.value)
    setTextInput(e.target.value)
    // go to home page
  }


  return (
    <div >
      <div className="DeleteReaderAcc">
        <h1>Delete Account</h1>
        <div className="viewContent">
          Really? Are you leaving?
          Voice Reader will be so sad to see you go. Once you delete your account, all your precious collections and hard working will disappear into thin air!
          If this is your truly wish, please enter your User ID to comfirm and permanently delete your account.<br />Your ID is:
        </div>
        <div className="viewInput">
          <div><span>{user.user_id}</span></div>
          <input name="userId" placeholder='Your account ID' onChange={onChangeText} />
          <div><span className="textError">{textError}</span></div>
        </div>
        <div className='viewBtns'>

          <div className='viewBtn' onClick={onCancel}>
            <span>Cancel</span>
          </div>
          <div className='viewBtn viewBtnDelete' onClick={onDelete}>
            <span>Delete</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteReaderAcc;
