import axios from 'axios';
import config from "../config";

export function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


export const sendFeedback = (param, cb) => {
  const linkGoogleForm = config.formBuyProduct.url;
  const dataReport = {
    ...param,
    device: '',
    version: `Web: ${''} - osVersion: ${'1.0.0'}`,
  }

  const formData = new FormData();
  formData.append(config.formBuyProduct.payload.userId, dataReport.userId || 'unKnown');
  formData.append(config.formBuyProduct.payload.name, dataReport.name || 'unKnown');
  formData.append(config.formBuyProduct.payload.desc, dataReport.desc);
  formData.append(config.formBuyProduct.payload.version, dataReport.version);
  formData.append(config.formBuyProduct.payload.device, dataReport.device);
  formData.append(config.formBuyProduct.payload.source, dataReport.source);
  formData.append(config.formBuyProduct.payload.data, dataReport.data);
  console.log('formDataformData', formData, linkGoogleForm)

  axios.post(linkGoogleForm, formData).then(() => {
    console.log('sendFeedback ok')
    cb(true)
  }).catch((er) => {
    cb(false)
    console.log(er, 'sendFeedback', er)
  });
}

export const sendSupport = (param, cb) => {
  const linkGoogleForm = config.formSupport.url;

  const formData = new FormData();
  formData.append(config.formSupport.payload.name, param.name || 'name');
  formData.append(config.formSupport.payload.email, param.email || 'email');
  formData.append(config.formSupport.payload.subject, param.subject || 'subject');
  formData.append(config.formSupport.payload.message, param.message || 'message');
  console.log('formDataformData', formData, linkGoogleForm)

  axios.post(linkGoogleForm, formData).then(() => {
    console.log('sendFeedback ok')
    cb(true)
  }).catch((er) => {
    cb(false)
    console.log(er, 'sendFeedback', er)
  });
}
