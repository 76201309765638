import React from 'react';

import './styles.css';

function Terms() {


  const data = [
    {
      title: 'Introduction/ Welcome to Effortless English',
      desc: 'Thank you for using Effortless English (“Effortless English”, “we”, “us”, “our”). These are the terms of use (“Terms”), which apply to your use of the Site and our mobile applications (“App”) and related services (collectively, such services, including any new features and applications, and the Site, the “Service(s)”). For information on how information is gathered and used at the Site and on the App, please go to our <a href="/privacy">Privacy Policy</a>. The Privacy Policy is incorporated into these Terms by reference. If you do not agree to these Terms, you must discontinue using the Services and terminate your registration with us. The Services is owned by Effortless English.',
    },
    {
      title: 'Registration for services',
      desc: 'You must register for an Account by: (i) connecting through an available social account (including, but not limited to Apple, Google Plus); or (ii) providing a username, a valid e-mail address and any other information we may require from time to time. You are responsible for maintaining the confidentiality of your account details and you are fully responsible for any actions that take place through your account. Your Account must be used only by you and must not be shared with, or transferred to any other individual. You must immediately notify us by email at support@Effortless English-english.com of any suspected or actual, unauthorized or fraudulent use of your Account or any other breach of security. Once you have an account, you can create an online profile. When creating your profile, you agree to (i) provide accurate, current and complete information; and (ii) be personally responsible for the information you provide in your profile. We reserve the right to suspend or terminate your account if any information you provided in your profile proves to be inaccurate, untrue or does not comply with our Terms.',
    },
    {
      title: 'Link to and from other websites (Third Party Websites)',
      desc: 'The Services may contain third-party owned content and links to other websites, including Facebook and Google Plus (“Third Party Websites”). We do not endorse, sponsor, recommend, or otherwise accept responsibility for any Third Party Websites. In addition, Third Party Websites are not under our control and we are not responsible for the content or privacy practices of the Third Party Websites, including, without limitation, links contained on Third Party Websites or any changes or updates to Third Party Websites. You further acknowledge and agree that Effortless English shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods or services available on or through Third Party Websites. Any dealings you have with Third Parties found while using the Services are between you and the Third Party, and you agree that Effortless English is not liable for any loss or claim that you may have against any such Third Party.',
    },
    {
      title: 'Termination of registration',
      desc: 'Effortless English may terminate your access and use of the Services immediately at any time, for any reason, and at such time you will have no further right to use the Services if, at Effortless English’s sole discretion, you fail to comply with any of the Terms.',
    },
    {
      title: 'Refund Policy',
      desc: `You may cancel your subscription to Effortless English at any time for any reason whatsoever. However, a refund will only be issued if you subscribed to Effortless English via Apple App Store/ iTunes (iOS) on the mobile app or via 2Checkout (now Verifone) when you bought on the web. <br/>
          Otherwise, your refund request should not be accepted as we adopted the freemium model, which gives you free access to PRO features before you need to upgrade. Therefore, we suggest you should only subscribe to Effortless English Pro after careful consideration. It\'s advisable to make the most of our free version first.
      `,
    },
    {
      title: 'Deleting Account',
      desc: `You can delete your account and terminate your use of the Services at any time by locating the ‘delete profile’ option in the settings section of your Profile. Once you have deleted your Account, you will no longer have access to your Profile and we will delete all data associated with your account. We accept no liability for the deletion of information or content from your Profile. You acknowledge and accept that certain User Content may remain after the deletion of your Profile and that the User Content Licence shall not be revoked or terminated. You can not recover the account you have deleted. If you have a Premium Subscription when you delete your Account, you will not receive a refund for any time remaining on your subscription. Each email address can only be connected to one Effortless English account at a time, and deleting an account won't free the email up for use on another account. If you want to use this account’s email address on another Effortless English account, you need to change the email address connected to your account on the settings page before you delete your account. Once you change the email address, you can delete the account and use your original email address on another Effortless English account.`,
    },
    {
      title: 'For Pro Account',
      desc: `Pro Accounts cannot be transferred under any circumstances. You may not: (a) copy, modify or distribute the Service for any purpose; (b) transfer, sublicense, lease, lend, rent or otherwise distribute the Service to any third party; (c) decompile, reverse-engineer, disassemble, or create derivative works of the Service, or otherwise attempt to discover the source code of the Service; (d) make the functionality of the Service available to multiple users through any means; or (e) use the Service in any unlawful manner, for any unlawful purpose, or in any manner inconsistent with these Terms. In case of any fraudulent activities related to using counterfeit cards or stolen cards, etc... to subscribe for Pro Accounts, the system will automatically revoke Pro subscriptions and block those cards.`,
    },
    {
      title: 'Restrictions',
      desc: `You agree not to (a) interfere with, damage, impair, or disable the Service’s operation, by any means (whether through automated means or otherwise), including uploading or otherwise disseminating viruses, worms, spyware, adware or other malicious code; (b) make unsolicited offers, advertisements, proposals, or send junk mail or spam to other Users, or use the Service for commercial purposes; (c) use any robot, spider, scraper, or other automated means to access the Service for any purpose without our express consent or bypass our robot exclusion headers or similar measures; (d) remove, circumvent, disable, damage or otherwise interfere with the Service’s security-related features, features that prevent or restrict the use or copying of any part of the Service, or features that enforce Service limitations; (e) attempt to gain unauthorized access to the Service, other Accounts, computer systems or networks connected to the Service through hacking, password mining, or any other means; (f) deep-link to the Service, and you agree you will promptly remove any links that Company finds objectionable in its sole discretion; (g) send bulk e-mails, surveys, or other mass messaging, whether commercial in nature or not; (h) solicit personal information from minors, or submit or transmit pornography; (i) reformat or frame any portion of the Service; (k) take any action that imposes, or may impose, in our sole discretion, an unreasonable or disproportionately large load on our technology infrastructure or otherwise make excessive traffic demands of the Service; (l) impersonate another person or represent yourself as affiliated with us, our staff or other industry professionals; (m) solicit a User’s password or other Account information; or (n) harvest User names, addresses, or e-mail addresses for any purpose.`,
    },
    {
      title: 'Limitation of liability',
      desc: `In no event shall Effortless English or its affiliates be liable to you for any indirect, incidental, special, consequential or exemp`,
    },
    {
      title: 'Disclaimer of warranties',
      desc: `You understand that your use of the Services is at your sole risk. The Services are provided to you on an “as is” and “as available” basis. Effortless English makes no representations or warranties of any kind with respect to the Services, whether express, implied or statutory, including, but not limited to the warranties of merchantability, fitness for a particular purpose, title and non-infringement.
      Effortless English makes no warranty that (i) The Services will meet your requirements, (ii) The Services will be uninterrupted, timely, secure, or error-free, (iii) The results obtained from your use of the Services will be accurate, reliable, complete
      Access and use of the Services may be slow, limited or unavailable during periods of peak demand, system upgrades, malfunctions or scheduled or unscheduled maintenance, or for any other reason beyond our reasonable control.`,
    },
    {
      title: 'Modification of terms',
      desc: `We may change the Terms from time to time by updating this posting and changing the Effective Date referenced below. Please visit the Terms area each time you visit the Site or use the Services to keep up to date with the current terms regarding your use of the Services. Your continued use of the Services constitutes an affirmative acknowledgement by you of the Terms modifications and your agreement to abide and be bound by the Terms as modified.`,
    },
    {
      title: 'Privacy Policy',
      desc: `By using the Services, you agree to be legally bound by our <a href="/privacy">Privacy Policy</a>.`,
    },
    {
      title: 'General',
      desc: `These Terms are the entire agreement between you and Effortless English. They supersede any and all prior or contemporaneous agreements between you and Effortless English relating to your use of the Site or the Services. If any part of these Terms is determined to be invalid or unenforceable, it will not impact any other provision of these Terms, all of which will remain in full force and effect. The failure of Effortless English to partially or fully exercise any rights or the waiver of Effortless English of any breach of these Terms by you, shall not prevent a subsequent exercise of such right by Effortless English or be deemed a waiver by Effortless English of any subsequent breach by you of the same or any other term of these Terms. The rights and remedies of Effortless English under these Terms and any other applicable agreement between you and Effortless English shall be cumulative, and the exercise of any such right or remedy shall not limit Effortless English's right to exercise any other right or remedy. These Terms are governed by, and will be interpreted in accordance with, the laws of Vietnam, without regard to any choice of law provisions.`,
    },
    {
      title: 'Contact Us',
      desc: `Please contact us with any question you might have about these Terms by sending an email to effortlessenglishcontact@gmail.com entitled “Questions about Terms”`,
    },
    {
      title: 'EFFECTIVE DATE: 31/05/2023',
      desc: ``,
    },
  ]

  return (
    <div>
      <div className="Terms">
        <h1><strong>Terms &amp; Conditions</strong></h1>
        <br />
        <p>These terms and conditions applies to the Effortless English app (hereby referred to as "Application") for mobile devices that was created by CYF Studio (hereby referred to as "Service Provider") as a Free service.</p>
        <br /><p>Upon downloading or utilizing the Application, you are automatically agreeing to the following terms. It is strongly advised that you thoroughly read and understand these terms prior to using the Application. Unauthorized copying, modification of the Application, any part of the Application, or our trademarks is strictly prohibited. Any attempts to extract the source code of the Application, translate the Application into other languages, or create derivative versions are not permitted. All trademarks, copyrights, database rights, and other intellectual property rights related to the Application remain the property of the Service Provider.</p><br />
        <p>The Service Provider is dedicated to ensuring that the Application is as beneficial and efficient as possible. As such, they reserve the right to modify the Application or charge for their services at any time and for any reason. The Service Provider assures you that any charges for the Application or its services will be clearly communicated to you.</p><br />
        <p>The Application stores and processes personal data that you have provided to the Service Provider in order to provide the Service. It is your responsibility to maintain the security of your phone and access to the Application. The Service Provider strongly advise against jailbreaking or rooting your phone, which involves removing software restrictions and limitations imposed by the official operating system of your device. Such actions could expose your phone to malware, viruses, malicious programs, compromise your phone's security features, and may result in the Application not functioning correctly or at all.</p><div><p>Please note that the Application utilizes third-party services that have their own Terms and Conditions. Below are the links to the Terms and Conditions of the third-party service providers used by the Application:</p><ul><li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
          <li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
          <li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li>
        </ul>
        </div>
        <br />
        <p>Please be aware that the Service Provider does not assume responsibility for certain aspects. Some functions of the Application require an active internet connection, which can be Wi-Fi or provided by your mobile network provider. The Service Provider cannot be held responsible if the Application does not function at full capacity due to lack of access to Wi-Fi or if you have exhausted your data allowance.</p><br />
        <p>If you are using the application outside of a Wi-Fi area, please be aware that your mobile network provider's agreement terms still apply. Consequently, you may incur charges from your mobile provider for data usage during the connection to the application, or other third-party charges. By using the application, you accept responsibility for any such charges, including roaming data charges if you use the application outside of your home territory (i.e., region or country) without disabling data roaming. If you are not the bill payer for the device on which you are using the application, they assume that you have obtained permission from the bill payer.</p><br />
        <p>Similarly, the Service Provider cannot always assume responsibility for your usage of the application. For instance, it is your responsibility to ensure that your device remains charged. If your device runs out of battery and you are unable to access the Service, the Service Provider cannot be held responsible.</p><br />
        <p>In terms of the Service Provider's responsibility for your use of the application, it is important to note that while they strive to ensure that it is updated and accurate at all times, they do rely on third parties to provide information to them so that they can make it available to you. The Service Provider accepts no liability for any loss, direct or indirect, that you experience as a result of relying entirely on this functionality of the application.</p><br />
        <p>The Service Provider may wish to update the application at some point. The application is currently available as per the requirements for the operating system (and for any additional systems they decide to extend the availability of the application to) may change, and you will need to download the updates if you want to continue using the application. The Service Provider does not guarantee that it will always update the application so that it is relevant to you and/or compatible with the particular operating system version installed on your device. However, you agree to always accept updates to the application when offered to you. The Service Provider may also wish to cease providing the application and may terminate its use at any time without providing termination notice to you. Unless they inform you otherwise, upon any termination, (a) the rights and licenses granted to you in these terms will end; (b) you must cease using the application, and (if necessary) delete it from your device.</p><br />
        <strong>Changes to These Terms and Conditions</strong>
        <p>The Service Provider may periodically update their Terms and Conditions. Therefore, you are advised to review this page regularly for any changes. The Service Provider will notify you of any changes by posting the new Terms and Conditions on this page.</p><br />
        <p>These terms and conditions are effective as of 2023-05-15</p><br /><strong>Contact Us</strong>
        <p>If you have any questions or suggestions about the Terms and Conditions, please do not hesitate to contact the Service Provider at effortlessenglishcontact@gmail.com.</p><hr /><p>This Terms and Conditions page was generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy Policy Generator</a>
        </p>

      </div>
    </div>
  );
}

export default Terms;
