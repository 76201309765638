import React from 'react';
import './styles.css';

function Support(props) {
  return (
    <div>
      <div className="Support">
        <h2>Contact Us:</h2>
        <div className="viewContent">
          <p>Name: Nguyen van duc</p>
          <p>Email: dunnv0304@gmail.com</p>
          <p>Skype: ducnv34</p>
        </div>
      </div>
    </div>
  );
}

export default Support;
