const config = {
  formSupport: {
    url: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSe1NuQAzIThiZmsa_orQXyNG8zXPJIGP-rBJAixJnHbNqnA7A/formResponse',
    payload: {
      name: "entry.1230813874",
      email: "entry.9676087",
      subject: "entry.1803240528",
      message: "entry.1237467967",
    }
  },
  formBuyProduct: {
    url: 'https://docs.google.com/forms/u/0/d/e/1FAIpQLScUZCg3BZDc5ylfoPG_jSqAUYHLzprhgYgaBdRnAGbmQ16Fbw/formResponse',
    payload: {
      userId: 'entry.904995853',
      name: 'entry.2144837468',
      desc: 'entry.1230813874',
      version: 'entry.9676087',
      device: 'entry.1803240528',
      source: 'entry.1237467967',
      data: 'entry.1768904391',
    }
  },
}

export default config