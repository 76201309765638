import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { initializeApp } from "firebase/app";
import Swal from "sweetalert2";
import { getAnalytics, logEvent } from "firebase/analytics";
import Helmet from 'react-helmet';
import { sendFeedback, sendSupport } from "../../lib/helper";
import config from "../../config";
import './styles.css';
import en from './locales/en.json';
import vi from './locales/vi.json';

const slideHeaderData = [
  './home/screen-small-1.png',
  './home/screen-small-2.png',
  './home/screen-small-3.png',
  './home/screen-small-4.png',
  './home/screen-small-5.png',
  './home/screen-small-6.png',
  './home/screen-small-7.png',
  './home/screen-small-8.png',
]

const chanelSupportDefault = [
  {
    "country": "VN",
    "name": "Zalo",
    "image": "./zalo-sharelogo.png",
    "link": "https://zalo.me/0829100363",
  },
  {
    "name": "Messenger",
    "image": "./messenger-icon.png",
    "link": "https://www.messenger.com/t/109623182211975",
  },
  {
    "name": "Telegram",
    "image": "./telegram-icon.png",
    "link": "https://t.me/effortlessenglishcontact",
  }
]

const buyLinkTemplate = 'https://secure.2checkout.com/checkout/buy?merchant=254805753686&tpl=default&prod={0}&qty=1'

const pricingsDataUs = [
  [

    { productId: '50_years_3st', name: 'Lifetime', pricing: '$74.99', pricingPerMonth: '$ 0.12', day: 18250, priceOrigin: '$2.319,92', isBest: true },
    { productId: '15_years', name: '15 years', pricing: '$69.99', pricingPerMonth: '$ 0.39', day: 5475, priceOrigin: '$695,97', isBest: false },
    { productId: '5_years', name: '5 years', pricing: '$33.99', pricingPerMonth: '$ 0.57', day: 1825, priceOrigin: '$231,99', isBest: false },
  ],
  [
    { productId: '2_years', name: '2 years', pricing: '$15.99', pricingPerMonth: '$ 0.66', day: 730, priceOrigin: '$92,80', isBest: false },
    { productId: '1_year', name: '1 year', pricing: '$11.99', pricingPerMonth: '$ 0.99', day: 365, priceOrigin: '$46,40', isBest: false },
    { productId: '1_month', name: '1 month', pricing: '$1.99', pricingPerMonth: '$ 1.99', day: 31, priceOrigin: '$3,94', isBest: false },
  ]
]


const pricingsDataVn = [
  [
    { productId: '50_years_3st', name: 'Gói trọn đời', pricing: '1.799.000 đ', pricingPerMonth: '3.000 đ', day: 18250, priceOrigin: '54.750.000 đ', isBest: true },
    { productId: '15_years', name: '15 năm', pricing: '1.699.000 đ', pricingPerMonth: '9.400 đ', day: 5475, priceOrigin: '16.425.000 đ', isBest: false },
    { productId: '5_years', name: '5 năm', pricing: '799.000 đ', pricingPerMonth: '13.300 đ', day: 1825, priceOrigin: '5.475.000 đ', isBest: false },
  ],
  [
    { productId: '2_years', name: '2 năm', pricing: '399.000 đ', pricingPerMonth: '16.600 đ', day: 730, priceOrigin: '2.190.000 đ', isBest: false },
    { productId: '1_year', name: '1 năm', pricing: '299.000 đ', pricingPerMonth: '25.000 đ', day: 365, priceOrigin: '1.095.000 đ', isBest: false },
    { productId: '1_month', name: '1 tháng', pricing: '49.000 đ', pricingPerMonth: '49.000 đ', day: 31, priceOrigin: '93.000 đ', isBest: false },
  ]
]


const slideClientData = [
  {
    avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjV7Hs4CZWkjxwo2gLUXTrFvzc7wT0zg_Dm1E95AD2Z29w',
    name: 'Sunny Nguyễn',
    comment: 'commentSunnyNguyen'
  },
  {
    avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjUi9IF0CaDGPpd-MtTdqd_Lb4Z2ExEl4eCWUmynuimGqMk',
    name: 'Trâm Bảo',
    comment: 'commentTramBao'
  },
  {
    avatar: 'https://lh3.googleusercontent.com/a/ACg8ocLEJDF8MxjwEtWmeuLvxbz88fZeCNN5Njv_aAzc71z7=mo',
    name: 'Пупсик Красавчег',
    comment: 'commentKpac'
  },
  {
    avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjWl8FExd39Evy0cMbkRSrMc_UdZg2Z_-hZQOepXZ7gp1_E3',
    name: 'Bruno Dart',
    comment: 'commentBruno'
  },
  {
    avatar: 'https://lh3.googleusercontent.com/a/ACg8ocIu352Cn-3REaf7ndz14p5_6bTdjwTA1jUEzqx76DAg=mo',
    name: 'Aydın Genç',
    comment: 'commentAydın'
  },
  {
    avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjXAx2mNXNjhMvJv1P1SKzONI7kjXdsfUz9vQUAojQ1Vq5I',
    name: 'abed ahcene',
    comment: 'commentAbed'
  },
  {
    avatar: 'https://lh3.googleusercontent.com/a-/ALV-UjW6kOBXgLz1Ymo9TPSKikosZs8HmhONt8Bh3oZaR-5tAk1L',
    name: 'Hiền Trần Thúy',
    comment: 'commentHienTran'
  },
]

let timeHeader = null;
let timeClient = null;
const timeAutoNext = 20000
const keySaveLang = 'keySaveLang'

const firebaseConfig = {
  apiKey: "AIzaSyBrPSTHrDEkTUL9i-WUlWgoCdCCaGeH2lM",
  authDomain: "voice-reader-35489.firebaseapp.com",
  projectId: "voice-reader-35489",
  storageBucket: "voice-reader-35489.appspot.com",
  messagingSenderId: "156850034699",
  appId: "1:156850034699:web:b977196b14565b3a058f0e",
  measurementId: "G-6RWZW56FXH"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app)

function Home(props) {
  const [lang, setLang] = useState(props.lang || '')


  const [showPopup, setShowPopup] = useState(false)

  const [indexSliderHeader, setIndexSliderHeader] = useState(0)
  const widthSliderHeader = 458;
  const widthSliderHeaderStr = `${widthSliderHeader}px`

  const [indexSliderClient, setIndexSliderClient] = useState(0)
  const widthSliderClient = window.innerWidth;
  const widthSliderClientStr = `${widthSliderClient}px`

  const lenHeader = slideHeaderData.length;
  const lenClient = slideClientData.length;

  useEffect(() => {
    logEvent(analytics, "userViewWeb")
  }, [])


  const changeLanguage = useCallback((newLang) => {
    logEvent(analytics, "change_language_to" + newLang)
    localStorage.setItem(keySaveLang, newLang)
    setLang(newLang)
  }, [])

  const onBuy = (productId, isBest) => {
    if (isBest) {
      setShowPopup(true)
    } else {
      const linkBuy = buyLinkTemplate.replace('{0}', productId)
      logEvent(analytics, "onBuy-" + productId)
      window.open(linkBuy, '_blank')
    }
  }

  const langDefault = useMemo(() => {
    const langCache = localStorage.getItem(keySaveLang)
    if (langCache) return langCache
    const languages = navigator.languages || []
    return (languages.includes('vi') || languages.includes('vi-VN')) ? 'vi' : ' en'
  }, [])

  const strings = useMemo(() => {
    const newLang = lang || langDefault
    return newLang === 'en' ? en : vi
  }, [lang, langDefault])


  const pricingsData = useMemo(() => {
    const newLang = lang || langDefault
    return newLang === 'en' ? pricingsDataUs : pricingsDataVn
  }, [lang, langDefault])

  const chanelSupport = useMemo(() => {
    const newLang = lang || langDefault
    return newLang === 'en' ? chanelSupportDefault.slice(1) : chanelSupportDefault
  }, [lang, langDefault])


  const onSetIndexSliderHeader = (newIndex) => {
    setIndexSliderHeader(newIndex)
  }

  useEffect(() => {
    autoSliderHeader(indexSliderHeader)
  }, [indexSliderHeader])

  const autoSliderHeader = (_indexSliderHeader) => {
    timeHeader && clearTimeout(timeHeader)
    timeHeader = setTimeout(() => {
      const nextIndexSliderHeader = (_indexSliderHeader + 1) % lenHeader
      setIndexSliderHeader(nextIndexSliderHeader)
    }, timeAutoNext);
  }

  const onSetIndexSliderClient = (newIndex) => {
    setIndexSliderClient(newIndex)
  }

  useEffect(() => {
    autoSliderClient(indexSliderClient)
    return () => {
      timeClient && clearTimeout(timeClient)
      timeHeader && clearTimeout(timeHeader)
    }
  }, [indexSliderClient])

  const autoSliderClient = (_indexSliderClient) => {
    timeClient && clearTimeout(timeClient)
    timeClient = setTimeout(() => {
      const nextIndexSliderClient = (_indexSliderClient + 1) % lenClient
      setIndexSliderClient(nextIndexSliderClient)
    }, timeAutoNext);
  }

  const onGoAppleStore = () => {
    logEvent(analytics, "onGoAppleStore")
    window.open('https://apps.apple.com/us/app/effortless-english-learning/id6449653897')
  }

  const onGoGoogleStore = () => {
    logEvent(analytics, "onGoGoogleStore")
    window.open('https://play.google.com/store/apps/details?id=com.cyf.effortlessEnglish')
  }


  const onSubmitSupportForm = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const nameDom = e.target.querySelector(`[name="${config.formSupport.payload.name}"]`)
    const emailDom = e.target.querySelector(`[name="${config.formSupport.payload.email}"]`)
    const subjectDom = e.target.querySelector(`[name="${config.formSupport.payload.subject}"]`)
    const messageDom = e.target.querySelector(`[name="${config.formSupport.payload.message}"]`)
    const name = nameDom.value;
    const email = emailDom.value;
    const subject = subjectDom.value;
    const message = messageDom.value;
    sendSupport({ name, email, subject, message }, (isSuccess) => {
      if (isSuccess || true) {
        Swal.fire({
          icon: "success",
          title: strings.sendSupportSuccess,
          showConfirmButton: false,
          timer: 1500
        });
        nameDom.value = ''
        subjectDom.value = ''
        emailDom.value = ''
        messageDom.value = ''
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    })
  }

  return (
    <div>
      <Helmet>
        <title>{props.lang === 'en' ? 'Great application to learn English using the Effortless English method' : 'Ứng dụng tuyệt vời để học tiếng anh bằng phương pháp Effortless English'}</title>
      </Helmet>
      <div className="preloader" style={{ display: 'none' }}>
        <div className="sk-folding-cube">
          <div className="sk-cube1 sk-cube"></div>
          <div className="sk-cube2 sk-cube"></div>
          <div className="sk-cube4 sk-cube"></div>
          <div className="sk-cube3 sk-cube"></div>
        </div>
      </div>
      <div className="mainmenu-area affix-top" data-offset-top="100" data-spy="affix">
        <div className="container">
          <div className="navbar-header">
            <button className="navbar-toggle" data-target="#primary-menu" data-toggle="collapse" type="button">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <h1 className="navbar-brand logo" title={strings.Effortless_English}>
              <span style={{ height: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>{strings.Effortless_English}</span>
            </h1>
          </div>
          <nav className="collapse navbar-collapse" id="primary-menu">
            <ul className="nav navbar-nav navbar-right">
              <li className=""><a href="#home-page">{strings.Home}</a></li>
              <li className=""><a href="#price-page">{strings.Pricing}</a></li>
              <li className=""><a href="#service-page">{strings.Services}</a></li>
              <li className=""><a href="#feature-page">{strings.Features}</a></li>
              <li className=""><a href="#client-says">{strings.Client_Says}</a></li>
              <li><a href="#contact-page">{strings.Contact}</a></li>
            </ul>
          </nav>
          <div className="langContainer">
            <span className="langContainerTitle">{strings.Language}</span>
            <div className={`langItem ${(lang || langDefault) === 'vi' ? 'langItemActive' : ''}`} onClick={() => changeLanguage('vi')}>
              <img alt={"Vi"} src={'./home/vn-icon.png'} />
            </div>
            <div className={`langItem ${(lang || langDefault) === 'en' ? 'langItemActive' : ''}`} onClick={() => changeLanguage('en')}>
              <img alt={'En'} src={'./home/en-icon.png'} />
            </div>
          </div>
        </div>
      </div>
      <header className="header-area overlay full-height relative v-center" id="home-page"
        style={{ backgroundPosition: '50% 0px' }}>
        <div className="absolute anlge-bg"></div>
        <div className="container">
          <div className="row v-center">
            <div className="col-xs-12 col-md-7 header-text">
              <h2>{strings.Change_your_English_studies}</h2>
              <p>{strings.Change_your_English_studies_desc}</p>
              <div className="view_btn_store">
                <div className="market-btn apple-btn" role="button" onClick={onGoAppleStore}>
                  <span className="market-button-title">{strings.App_Store}</span>
                </div>
                <div className="market-btn google-btn" role="button" onClick={onGoGoogleStore}>
                  <span className="market-button-title">{strings.Google_Play}</span>
                </div>
              </div>
              <a className="button white" data-fancybox="" href="https://www.youtube.com/watch?v=RtlkOZUe_4M">{strings.Watch_video}</a>
            </div>
            <div className="hidden-xs hidden-sm col-md-5 text-right">
              <div className="screen-box screen-slider owl-carousel owl-theme owl-responsive--1 owl-center owl-loaded">
                <div className="owl-stage-outer">
                  <div className="owl-stage"
                    style={{
                      transition: 'all 1s ease 0s', width: '3664px', transform: `translate3d(${indexSliderHeader * widthSliderHeader * -1}px, 0px, 0px)`
                    }}>
                    {slideHeaderData.map(item => {
                      return (
                        <div key={item} className="owl-item" style={{ width: widthSliderHeaderStr }}>
                          <div className="item">
                            <img alt={item} src={item} />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className="owl-controls">
                  <div className="owl-dots" >
                    {slideHeaderData.map((item, index) => <div key={item} onClick={() => onSetIndexSliderHeader(index)} className={`owl-dot ${indexSliderHeader === index ? 'active' : ''}`}><span></span></div>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="price-area overlay section-padding" id="price-page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-sm-offset-3 text-center">
              <div className="page-title">
                <h2>{strings.title_pricing_Effortless_English}</h2>
                <p>{strings.bankDesc2}</p>
              </div>
            </div>
          </div>

          {pricingsData.map((pricingData, index) => {
            return (
              <div className="row" key={`${index}`}>
                {pricingData.map(pricingItem => {
                  return (<div key={pricingItem.name} className="col-xs-12 col-sm-4">
                    <div className="price-table viewContainerPricing">
                      {pricingItem.isBest && <span className="price-info"><span className="ti-crown"></span></span>}
                      <h3 className="text-uppercase price-title">{pricingItem.name}</h3>
                      <hr />
                      <ul className="list-unstyled">
                        <li className={pricingItem.isBest ? "greenText" : ''}>{pricingItem.isBest ? strings.descLifetimeDay : `+ ${pricingItem.day} ${strings.day} Pro`}</li>
                        <li>
                          {pricingItem.isBest ? <span>&nbsp;</span> :
                            <span><strong className="amount">{pricingItem.pricingPerMonth}</strong>/{strings.Month}</span>
                          }
                        </li>
                        <li>{strings.TotalOrigin}: <span className="priceOrigin">{pricingItem.priceOrigin}</span></li>
                        <li>{strings.TotalDiscount}: <span className="pricingTotal">{pricingItem.pricing}</span></li>
                      </ul>
                      <hr />
                      {pricingItem.isBest && <div className="viewOff">
                        <span>96%</span>
                        <span>Off</span>
                      </div>}
                      <button className={`button ${pricingItem.isBest ? 'buttonLifeTime' : ''}`} onClick={() => onBuy(pricingItem.productId, pricingItem.isBest)} >{strings.PayNow}</button>
                    </div>
                  </div>)
                })}
              </div>
            )
          })}

          <div className="row policyBuy" id="pro-policy">
            <div className="col-xs-12" style={{ marginTop: '50px' }}>
              <h4>{strings.descPricing}</h4>
              <p dangerouslySetInnerHTML={{ __html: strings.descPricing2 }} />
              <p>{strings.descPricing1}</p>
              <p>{strings.descPricing0}</p>
              <p>{strings.descPricing3}</p>
              <p>{strings.descPricing4}</p>
              <p>{strings.descPricing5}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="gray-bg section-padding" id="service-page" tabIndex="-1">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <div className="box">
                <div className="box-icon viewImagePractice">
                  <img alt="Practice listening" src="./home/service-icon-1.png" />
                </div>
                <h4>{strings.Practice_listening}</h4>
                <p>{strings.Practice_listening_desc}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4">
              <div className="box">
                <div className="box-icon viewImagePractice">
                  <img alt="Practice speaking" src="./home/service-icon-2.png" />
                </div>
                <h4>{strings.Practice_speaking}</h4>
                <p>{strings.Practice_speaking_desc}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4">
              <div className="box">
                <div className="box-icon viewImagePractice">
                  <img alt="Practice speaking with tone" src="./home/service-icon-3.png" />
                </div>
                <h4>{strings.Practice_speaking_with_tone}</h4>
                <p>{strings.Practice_speaking_with_tone_desc}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="angle-bg sky-bg section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <div className="carousel slide caption-slider" data-ride="carousel" id="caption_slide">
                <div className="carousel-inner" role="listbox">
                  <div className="item row">
                    <div className="v-center">
                      <div className="col-xs-12 col-md-6">
                        <div className="caption-title animated fadeInUp" data-animation="animated fadeInUp">
                          <h2>{strings.Full_of_lessons}</h2>
                        </div>
                        <div className="caption-desc animated fadeInUp" data-animation="animated fadeInUp">
                          <p>{strings.Full_of_lessons_desc}</p>
                        </div>
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <div className="caption-photo one animated fadeInRight" data-animation="animated fadeInRight">
                          <img alt="Full of lessons" src="./home/screen-1.png" />
                        </div>
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <div className="caption-photo two animated fadeInRight" data-animation="animated fadeInRight">
                          <img alt="Original Effortless English" src="./home/screen-2.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item row">
                    <div className="v-center">
                      <div className="col-xs-12 col-md-6">
                        <div className="caption-title animated fadeInUp" data-animation="animated fadeInUp">
                          <h2>{strings.Subtitle}</h2>
                        </div>
                        <div className="caption-desc animated fadeInUp" data-animation="animated fadeInUp">
                          <p>{strings.Subtitle_desc}</p>
                        </div>
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <div className="caption-photo one animated fadeInRight" data-animation="animated fadeInRight">
                          <img alt="Day of the Dead" src="./home/screen-3.png" />
                        </div>
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <div className="caption-photo two animated fadeInRight" data-animation="animated fadeInRight">
                          <img alt="Day of the Dead Subtitle setting" src="./home/screen-4.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item row">
                    <div className="v-center">
                      <div className="col-xs-12 col-md-6">
                        <div className="caption-title animated fadeInUp" data-animation="animated fadeInUp">
                          <h2>{strings.Offline_mode}</h2>
                        </div>
                        <div className="caption-desc animated fadeInUp" data-animation="animated fadeInUp">
                          <p>{strings.Offline_mode_desc}</p>
                        </div>
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <div className="caption-photo one animated fadeInRight" data-animation="animated fadeInRight">
                          <img alt="Setting screen" src="./home/screen-5.png" />
                        </div>
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <div className="caption-photo two animated fadeInRight" data-animation="animated fadeInRight">
                          <img alt="My downloaded" src="./home/screen-6.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item row active">
                    <div className="v-center">
                      <div className="col-xs-12 col-md-6">
                        <div className="caption-title animated fadeInUp" data-animation="animated fadeInUp">
                          <h2>{strings.Leaderboard}</h2>
                        </div>
                        <div className="caption-desc animated fadeInUp" data-animation="animated fadeInUp">
                          <p>{strings.Leaderboard_desc}</p>
                        </div>
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <div className="caption-photo one animated fadeInRight" data-animation="animated fadeInRight">
                          <img alt="Learning Time" src="./home/screen-7.png" />
                        </div>
                      </div>
                      <div className="col-xs-6 col-md-3">
                        <div className="caption-photo two animated fadeInRight" data-animation="animated fadeInRight">
                          <img alt="Leaderboard" src="./home/screen-8.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ol className="carousel-indicators caption-indector">
                  <li className="" data-slide-to="0" data-target="#caption_slide">
                    <strong>{strings.Full_of_lessons}</strong><span dangerouslySetInnerHTML={{ __html: strings.Full_lessons_of_6_DVDs }} />
                  </li>
                  <li data-slide-to="1" data-target="#caption_slide" className="">
                    <strong>{strings.Subtitle}</strong><span dangerouslySetInnerHTML={{ __html: strings.Subtitle_desc2 }} />
                  </li>
                  <li data-slide-to="2" data-target="#caption_slide" className="">
                    <strong>{strings.Offline_mode}</strong><span dangerouslySetInnerHTML={{ __html: strings.Offline_mode_desc2 }} />
                  </li>
                  <li data-slide-to="3" data-target="#caption_slide" className="active">
                    <strong>{strings.Leaderboard}</strong><span dangerouslySetInnerHTML={{ __html: strings.Leaderboard_desc2 }} />
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="gray-bg section-padding" id="feature-page" tabIndex="-1">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-sm-offset-3 text-center">
              <div className="page-title">
                <h2>{strings.SPECIAL_FEATURES}</h2>
                <p>{strings.SPECIAL_FEATURES_desc}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="box">
                <h3>{strings.Bilingual_subtitles}</h3>
                <p>{strings.Bilingual_subtitles_desc}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="box">
                <h3>{strings.Learning_Time}</h3>
                <p>{strings.Learning_Time_desc}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="box">
                <h3>{strings.Streak}</h3>
                <p>{strings.Streak_desc}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="box">
                <h3>{strings.Continue_Playing}</h3>
                <p>{strings.Continue_Playing_desc}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="box">
                <h3>{strings.Leaderboard}</h3>
                <p>{strings.Leaderboard_desc3}</p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="box">
                <h3>{strings.Offline_mode}</h3>
                <p>{strings.Offline_mode_desc3}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial-area section-padding gray-bg overlay" id="client-says">
        <div className="">
          <div className="text-center">
            <div className="page-title">
              <h2>{strings.Client_says}</h2>
            </div>
          </div>
        </div>
        <div className="">
          {/* <div className="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2"> */}
          <div className="">
            <div className="testimonials owl-carousel owl-theme owl-responsive--1 owl-loaded">
              <div className="owl-stage-outer">
                <div className="owl-stage"
                  style={{ transform: `translate3d(${indexSliderClient * widthSliderClient * -1}px, 0px, 0px)`, transition: 'all 1s ease 0s', width: `${widthSliderClient * lenClient}px` }}>
                  {slideClientData.map((res, index) => {
                    const name = res.name
                    const comment = strings[res.comment]
                    return (
                      <div key={name} className="owl-item" style={{ width: widthSliderClientStr }}>
                        <div className="testimonial">
                          <div className="testimonial-photo">
                            <img alt={name} src={res.avatar} />
                          </div>
                          <h3>{name}</h3>
                          <p style={{ padding: '0 32px' }}>{comment}</p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="owl-controls">
                <div className="owl-nav">
                  {/* <div className="owl-prev" ><i className="ti-arrow-left"></i></div>
                    <div className="owl-next" ><i className="ti-arrow-right"></i></div> */}
                </div>
                <div className="owl-dots" >
                  {slideClientData.map((item, index) => <div key={index.toString()} onClick={() => onSetIndexSliderClient(index)} className={`owl-dot ${indexSliderClient === index ? 'active' : ''}`}><span></span></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="footer-area relative sky-bg" id="contact-page">
        <div className="absolute footer-bg"></div>
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-sm-offset-3 text-center">
                <div className="page-title">
                  <h2>{strings.Contact_with_us}</h2>
                  <p>{strings.Contact_with_us_desc}</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-4">
                <address className="side-icon-boxes">
                  <div className="side-icon-box">
                    <div className="side-icon">
                      <img alt="Telegram" src="./home/location-arrow.png" />
                    </div>
                    <p><strong>{strings.Telegram}</strong>
                      <a href="https://t.me/effortlessenglishcontact" target="_blank">@effortlessenglishcontact</a>
                    </p>
                  </div>
                  <div className="side-icon-box">
                    <div className="side-icon side-icon-message">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" fill="currentColor" /></svg>
                    </div>
                    <p><strong>{strings.messenger}</strong>
                      <a href="https://www.messenger.com/t/109623182211975" target="_blank">@effortlessenglish</a>
                    </p>
                  </div>
                  <div className="side-icon-box">
                    <div className="side-icon">
                      <img alt="Mail to" src="./home/mail-arrow.png" />
                    </div>
                    <p><strong>{strings.Email}</strong>
                      <a href="mailto:effortlessenglishcontact@gmail.com" target="_blank"><span>effortlessenglishcontact@gmail.com</span></a>
                    </p>
                  </div>
                </address>
              </div>
              <div className="col-xs-12 col-md-8">
                <form
                  onSubmit={onSubmitSupportForm}
                  className="contact-form" id="mG61Hd" method="POST" target="hidden_iframe">
                  <div className="form-double">
                    <input aria-label={strings.Your_name} autoComplete="off" className="form-control" name={config.formSupport.payload.name}
                      placeholder={strings.Your_name} required="required" spellCheck="false" type="text" />
                    <input aria-label={strings.Email_address} autoComplete="off" className="form-control" name={config.formSupport.payload.email}
                      placeholder={strings.Email_address} required="required" spellCheck="false" type="email" />
                  </div>
                  <input aria-label={strings.Subject} autoComplete="off" className="form-control" name={config.formSupport.payload.subject}
                    placeholder={strings.Subject} spellCheck="false" type="text" />
                  <textarea aria-label={strings.Your_message} autoComplete="off" className="form-control" name={config.formSupport.payload.message}
                    placeholder={strings.Your_message} required="required" rows="5" spellCheck="false"></textarea>
                  <button className="button" type="submit">{strings.Submit}</button>
                  <div className="alert alert-success hidden" role="alert">
                    <strong>{strings.Thank_you_for_your_email}</strong> {strings.Thank_you_for_your_email_2} <button
                      aria-label={strings.Close} className="close" data-dismiss="alert" role="close" type="button"><span
                        className="ti-close"></span></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 text-center textCopyright">
                <p>© Copyright 2023. All right reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className={`viewPopup ${showPopup ? 'active' : ''}`}>
        <div className="viewPopupMain">
          <div className="viewPopupMainTitle">
            {strings.viewPopupMainTitle}
          </div>
          <div className="viewPopupMainDesc">
            {strings.viewPopupMainDesc}
          </div>
          <div className="viewPopupMainContent">
            <span className="viewPopupMainContentTitle">{strings.Contact}:   </span>
            {chanelSupport.map((res) => {
              return <div className="viewPopupMainContentItem">
                <a href={res.link} target="_blank">
                  <img alt={res.name} src={res.image} />
                </a>
              </div>
            })}
          </div>
          <div class="close" onClick={() => setShowPopup(false)}>&times;</div>
        </div>
      </div>
    </div>
  );

}

export default Home;
