import React, { useState, useEffect } from 'react';
import './styles.css';

function DeleteSuccess(props) {
  return (
    <div className="DeleteSuccess">
      <h2>Account Deleted Successfully</h2>
      <div className="viewContent">
        Your account has been deleted, now you can go back to the app create another account and continue learning.
      </div>
    </div>
  );
}

export default DeleteSuccess;
