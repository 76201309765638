import React, { useState, useMemo, useEffect } from 'react';
import './styles.css';

const dayTime = 86400000
const timeTarget = 365 * dayTime
const timeStart = 1705622400000
const timeEnd = timeStart + timeTarget;
function TimeLeft(props) {
  const [time, setTime] = useState();


  const _setTime = () => {
    const curTime = new Date().valueOf();
    if (curTime >= timeEnd) setTime(0)
    else {
      const days = Math.ceil((timeEnd - curTime) / dayTime)
      setTime(days)
    }
  }

  useEffect(() => {
    _setTime()
  }, [])

  return (
    <div>
      <div className="TimeLeft">
        <span>{time}</span>
      </div>
    </div>
  );
}

export default TimeLeft;
